<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" type="primary" @click="toAddPage" class="mr-16"><i class="e-icon add"></i>新增</a-button>
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
    </template>

    <!-- 搜索栏 -->
    <template v-slot:handles-right>
      <a-form-model layout="inline"
        :form="searchForm"
        @submit="handleSearchSubmit"
        @submit.native.prevent
      >
        <a-form-model-item>
          <a-input style="width: 240px" v-model="searchForm.keyword" placeholder="请输入企业名称/营业执照"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select style="width: 160px" v-model="searchForm.companyType" placeholder="请选择企业类型"
            :getPopupContainer="node => node.parentNode || document.body"
          >
            <a-select-option value="">
              全部企业类型
            </a-select-option>
            <a-select-option value="1">
              工商型
            </a-select-option>
            <a-select-option value="2">
              个人型
            </a-select-option>
          </a-select>
        </a-form-model-item>    
        <a-form-model-item>
          <a-select style="width: 160px" v-model="searchForm.businessType" placeholder="请选择业务类型"
            :getPopupContainer="node => node.parentNode || document.body"
          >
            <a-select-option value="">
              全部业务类型
            </a-select-option>
            <a-select-option :value="item.id" v-for="item in templateList" :key="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item style="margin-right: 0">    
          <a-button class="mp-grey-btn" html-type="submit">
            查询
          </a-button>
          <a-button class="mp-grey-btn" style="margin-left: 8px" @click="resetSearchForm">
            重置
          </a-button>
        </a-form-model-item>    
      </a-form-model>
    </template>

    <!-- 列表 -->
    <div class="mp-table-wrap">
      <a-table :columns="columns" 
        :data-source="tableData" 
        rowKey="companyId"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >   
          <div slot="serial" slot-scope="text,record,index">{{index+1}}</div>
          <div slot="companyType" slot-scope="text">{{text|companyTypeText}}</div>
          <div slot="handles" slot-scope="text, record">
            <a-button type="link" v-if="!record.certificateNumber" size="small" @click="issueCertificateHandle(record)">颁发证书</a-button>
            <a-button type="link" v-else size="small" @click="viewCertificateHandle(record)">查看证书</a-button>
            <a-button type="link" size="small" @click="toDetail(record)">查看详情</a-button>
          </div>
      </a-table>
    </div>

    <!-- 查看会员证书 -->
    <a-modal v-model="certificateVisible" title="证书照片" centered :width="806" :footer="null">
      <div class="certificate-img-box">
        <span class="title">{{certificate.companyName}}</span>
        <span class="code">No:{{certificate.number}}</span>
      </div>
    </a-modal>
  </page-head-wrapper>
</template>

<script>
import { approvedCompanyList, issueCertificate } from '@/api/company'
import { templateList } from '@/api/system'
import { mapGetters } from 'vuex' 
const columns = [
  {
    align: 'center',
    title: '序号',
    width: '80px',
    scopedSlots: { customRender: 'serial'}
  },
  {
    align: 'center',
    title: '企业名称',
    dataIndex: 'companyName'
  },
  {
    align: 'center',
    title: '企业类型',
    dataIndex: 'companyType',
    scopedSlots: { customRender: 'companyType'}
  },
  {
    align: 'center',
    title: '营业执照',
    dataIndex: 'companyLicenseNumber',
  },
  {
    align: 'center',
    title: '负责人身份证号 ',
    dataIndex: 'companyChargePersonIdNumber',
  },
  {
    align: 'center',
    title: '公司地址',
    dataIndex: 'companyAddress',
  },
  {
    align: 'center',
    title: '联系人员',
    dataIndex: 'companyContactPerson'
  },
  {
    align: 'center',
    title: '联系电话',
    dataIndex: 'companyContactPhone'
  },
  {
    align: 'center',
    title: '业务类型',
    dataIndex: 'businessTypes',
    ellipsis: true,
  },
  {
    align: 'center',
    title: '相关操作',
    width: 200,
    scopedSlots: { customRender: 'handles'}
  },
]
export default {
  name: 'static-data-company-maintenance-list',
  keep_alive_tab: true,
  data() {
    return {
      // 搜索表单
      searchForm: {
        keyword: '',
        companyType: '',
        businessType: ''
      },
       
      // 列表
      tableData: [],
      columns,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true
      },

      certificateVisible: false,
      certificate: {
        companyName: '',
        number: ''
      },

      templateList: []
    }
  },
  computed: {
    ...mapGetters(['adminId'])
  },
  created() {
    this.getTemplateList()
  },
  filters: {
    companyTypeText: function(value) {
      return value == 1 ? '工商型': value == 2 ? '个人型' : '-'
    }
  },
  methods: {
    approvedCompanyList() {
      let {current, pageSize} = this.pagination
      let {keyword, companyType, businessType} = this.searchForm
      this.loading = true
      approvedCompanyList({
        current,
        size: pageSize,
        keyword,
        businessType,
        companyType
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.tableData = res.data.records.map(item => {
            console.log(item)
            let businessTypes = this.calcBusinessTypes(item.businessTypes)
            return {
              ...item,
              companyName: item.companyName || '-',
              companyLicenseNumber: item.companyLicenseNumber || '-',
              companyChargePersonIdNumber: item.companyChargePersonIdNumber || '-',
              companyAddress: item.companyAddress || '-',
              companyContactPerson: item.companyContactPerson || '-',
              companyContactPhone: item.companyContactPhone || '-',
              businessTypes,
            }
          })
          this.pagination.total = res.data.total
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.loading = false
      })
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.approvedCompanyList()
    },
    // 搜索表单
    handleSearchSubmit() {
      console.log(this.searchForm)
      this.pagination.current = 1
      this.approvedCompanyList()
    },
    // 重置搜索
    resetSearchForm() {
      console.log('resetSearchForm')
      this.pagination.current = 1
      this.searchForm.keyword = ''
      this.searchForm.businessType = ''
      this.searchForm.companyType = ''
      this.approvedCompanyList()
    },
    toDetail(record) {
      this.pushTabPage({
        path: '/static-data/company/maintenance/detail?id=' + record.companyId, 
      })
    },
    toAddPage() {
      this.pushTabPage({
        path: '/static-data/company/maintenance/add', 
      })
    },
    // 刷新操作
    onRefresh() {
      this.resetSearchForm()
    },
    // 颁发证书
    issueCertificateHandle(record) {
      this.$confirm({
        title: '颁发证书',
        content: '是否给该企业颁发产业联盟证书？',
        centered: true,
        onOk: () => {
          console.log('OK')
          this.$showLoading()
          issueCertificate({
            adminId: this.adminId,
            companyId: record.companyId,
            type: 1 // 证书类型 1 - 长三角时尚产业联盟
          }).then(res => {
            this.$hideLoading()
            console.log(res)
            if (res.code == 200) {
              this.$message.success('操作成功')
              this.approvedCompanyList()
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.warn(err);
          })
        },
        onCancel:() => {
          console.log('Cancel')
        },
      })
    },
    // 查看证书
    viewCertificateHandle(record) {
      this.certificate.companyName = record.companyName
      this.certificate.number = record.certificateNumber
      this.certificateVisible = true
    },
    // 获取业务类型列表
    getTemplateList() {
      templateList({parentId: '', type: 1}).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.templateList = res.data.filter(item => item.status == 1)
        } else {
          this.$message.error(res.msg || '获取业务类型出错')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.approvedCompanyList()
      })
    },
    calcBusinessTypes(value) {
      if (this.templateList && this.templateList.length > 0 && value) {
        let arr = value.split(',').map(item => +item)
        let filterArr = this.templateList.filter(item => arr.indexOf(item.id) >= 0)
        let resultStr = filterArr.map(item => item.name).join('、')
        return resultStr
      } else {
        return value || '-'
      }
    }
  }
}
</script>